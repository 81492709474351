<template src="./CreateNewListModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "../../../../../common/AppModal/AppModal";
import { addList } from "../../../../../../services/clubDetails/list.service";
import AppSnackBar from "../../../../../common/AppSnackBar/AppSnackBar";
import AppLoader from "../../../../../common/AppLoader/AppLoader";
export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
    AppSnackBar,
    "app-loader": AppLoader,
  },
  computed: {
    modalVisible() {
      return this.$store.state.club.deleteModalVisible; //need change
    },
  },
  data: function () {
    return {
      visibleDialog: true,
      clubId: this.$route.params.id,
      listName: "",
      errors: [],
    };
  },
  methods: {
    createNewList() {
      var payload = {
        clubId: this.$route.params.id,
        entityType: +this.$route.query.type,
        name: this.listName,
      };

      addList(payload)
        .then((response) => {
          let payload = {
            type: "success",
            Message: "New List Created Successfully.",
          };
          this.$store.dispatch("showNotification", payload);
          this.$emit("refetchLists",true);
          this.$emit("closeModal");
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    modalHandler(data) {
      this.$emit("closeModal");
    },
  },
};
</script>