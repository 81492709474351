<template src="./Lists.html"></template>
<style lang="scss">
@import "./Lists.scss";
</style>

<script>
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { Input } from "@progress/kendo-vue-inputs";
import { Button, ButtonGroup } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { DropDownButton } from "@progress/kendo-vue-buttons";
import DeleteListModal from "./modals/deleteListModal/DeleteListModal";
import CreateNewListModal from "./modals/createNewListModal/CreateNewListModal";
import EditListModal from "./modals/editListModal/EditListModal.vue";
import Breadcrumb from "../../../common/AppBreadCrumbs/BreadCrumbs.vue";
import AppLoader from "../../../common/AppLoader/AppLoader";
import AppSnackBar from "../../../common/AppSnackBar/AppSnackBar";
import { changeActiveStatus,removeList } from "../../../../services/clubDetails/list.service";
import moment from "moment";
import AppPopUp from "@/components/common/AppPopUp/AppPopUp.vue";
import GetAudienceModel from "@/models/audience/allAudience/GetAudienceModel";

export default {
  name: "Lists",
  components: {
    AppPopUp,
    buttongroup: ButtonGroup,
    Grid: Grid,
    toolbar: GridToolbar,
    "k-input": Input,
    kbutton: Button,
    Breadcrumb,
    dropdownlist: DropDownList,
    DropDownButton,
    DeleteListModal,
    CreateNewListModal,
    EditListModal,
    "app-loader": AppLoader,
    AppSnackBar,
    GridNoRecords,
  },
  data() {
    return {
      itemTagTypes: {
        1: "dpAudience",
        2: "ecAudience",
        3: "vAudience",
      },
      moduleTagTypes: {
        1: "digitalPass",
        2: "emailCampaign",
        3: "voucher",
      },
      childItemCodeType: {
        1: "dpLists",
        2: "ecLists",
        3: "vLists",
      },
      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },
      clubId: this.$route.params.id,
      searchWord: "",
      selectedField: "selected",
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: [10, 50, 100, 200, 500, 1000],
        previousNext: true,
      },
      selectedItems: [],
      documentIdPass: "",
      selectedMembers: [],
      selectedMemberList: [],
      listName: "listName",
      actions: "actions",
      sort: [],
      sortBy: "",
      sortDir: "",
      passStatuses: [
      ],
      actionsDropDown: [],
      passStatusValue: { id: 0, text: "Remane" },
      modalVisible: false,
      deleteModalVisible: false,
      editModalVisible: false,
      fieldrowId: "",
      timer: null,
      IsSearch: false,
      column: 1,
      allChecked: false,
      rowId: [],
    };
  },
  computed: {
    areAllSelected() {
      return (
        this.selectedItems?.length === this.listData?.items?.length &&
        this.listData.items?.length != 0
      );
    },
    columns() {
      return [
        {
          field: "selected",
          headerSelectionValue: this.areAllSelected,
           width: "42px",
        },
        {
          field: "ListName",
          title: "Name",
          cell: "listName",
          sortable: false,
        },
        {
          field: "audienceCount",
          title: "Total Audience",
          cell: "audienceCount",
          sortable: true,
        },
        {
          field: "createdOn",
          title: "Created On",
          cell: "createdOn",
          sortable: false,
        },
        {
          field: "lastUpdateOn",
          title: "Modified On",
          cell: "lastUpdateOn",
          sortable: false,
        },
        {
          field: "actions",
          title: "Action",
          cell: "actions",
          sortable: false,
          width: "90px",
        },
      ];
    },
    listData() {
      return this.$store.state.list.lists;
    },
    isLoading() {
      return this.$store.state.list.isLoading;
    },
    isShowMsg() {
      return this.$store.state.common.showMsg;
    },
    showMsg() {
      return this.$store.state.common.status;
    },
    fetchController() {
      return this.$store.state.list.fetchController;
    },
    total() {
      return this.listData ? this.listData.size : 0;
    },
    take() {
      return this.listData ?  this.listData.limit || 100 : 100;
    },
    skip() {
      return this.listData ? this.listData.offset : 0;
    },
  },
  watch: {
    "$route.query.type"() {
      this.searchWord = "";
      this.selectedItems = [];
      if (!this.hasGetAccess()) {
        this.$store.commit("SHOW_NOTIFICATION", {
          message: "You do not have access to view campaigns.",
          type: "warning",
        });
        this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
      }
      this.actionsDropDown = [
        ...(this.hasEditAccess() ? [{ dropId: 1, text: "Rename" }] : []),
        ...(this.hasDeleteAccess() ? [{ dropId: 2, text: "Delete" }] : []),
      ];
    },
  },
  mounted() {
    if (!this.hasGetAccess()) {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "You do not have access to view campaigns.",
        type: "warning",
      });
      this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
    }
    this.passStatuses = [
      ...(this.hasEditAccess() ? [{ id: 0, text: "Rename" }] : []),
      ...(this.hasDeleteAccess() ? [{ id: 1, text: "Delete" }] : []),
    ];
    this.actionsDropDown = [
      ...(this.hasEditAccess() ? [{ dropId: 1, text: "Rename" }] : []),
      ...(this.hasDeleteAccess() ? [{ dropId: 2, text: "Delete" }] : []),
    ];
    this.part;
    this.getAllList();
  },
  unmounted() {
    this.$store.commit("SET_ALL_LIST", []);
    this.selectedItems = [];
  },
  methods: {
    hasGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    getAllList() {
      var model = {
        clubId: this.$route.params.id,
        entityType: +this.$route.query.type,
        searchQuery: " ",
        limit: 100
      };
      this.$store.dispatch("getLists", model);
    },

    onHeaderSelectionChange(event) {
      var allChecked = event.event.target.checked;
      this.listData.items = this.listData.items.map((item) => {
        return { ...item, selected: allChecked };
      });
      if (allChecked) {
        this.selectedItems = [];
        let members = this.listData.items;
        for (const key in members) {
          if (Object.hasOwnProperty.call(members, key)) {
            this.selectedItems.push(members[key].id);
            this.selectedMemberList.push(members[key]);
          }
        }
      } else {
        this.selectedItems = [];
      }
    },
    onSelectionChange(event) {
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
      this.selectedItems = event.dataItems
        .filter((x) => x.selected === true)
        .map((x) => x.id);

      this.selectedMemberList = event.dataItems.filter(
        (x) => x.selected === true
      );
    },
    onSearch(e) {
      if (e.value == "") {
        this.IsSearch = true;
      }
      this.selectedItems = [];
      this.searchWord = e.value;
      this.$store.commit("SET_LOADER", true);

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.refetchLists(true)
      }, 800);
    },
    payloadGenerator() {
      let payload = new GetAudienceModel();
      payload = {
        ...payload,
        clubId: this.clubId,
        searchQuery: this.searchWord,
        entityType : +this.$route.query.type,
      };
      if (this.sortBy && this.sortDir) {
        payload.orderBy = `${this.sortBy} ${this.sortDir}`;
      }
      return payload;
    },
    refetchLists(resetPagination = false) {
      let payload = this.payloadGenerator();

      if (resetPagination) {
        payload = {
          ...payload,
          limit: this.take,
          offset: 0,
        };
      } else {
        payload = {
          ...payload,
          limit: this.take,
          offset: this.skip,
        };
      }
      this.$store.dispatch("getLists", payload);
    },
    sortChangeHandler: function (e) {
      this.IsSearch = true;
      this.sort = e.sort;
      if (e.sort.length > 0) {
        this.sortBy = e.sort[0].field;
        this.sortDir = e.sort[0].dir;
      } else {
        this.sortBy = "";
        this.sortDir = "";
      }

      this.refetchLists(true);
    },
    dataStateChange: function (event) {
      this.take = event.data.take;
      this.skip = event.data.skip;
      var model = {
        clubId: this.$route.params.id,
        entityType: +this.$route.query.type,
        searchQuery: this.searchWord,
        limit : event.data.take,
        offset: event.data.skip
      };
      this.$store.dispatch("getLists", model);
    },
    createList() {
      this.modalVisible = true;
    },
    deleteList() {
      this.rowId = this.selectedItems;
      this.deleteModalVisible = true;
    },
    handlePassStatusValue(e) {
      this.passStatusValue = e.value;
      var payload = {
        clubId: this.clubId,
        templateId: this.$route.query.template_id,
        passStatus: this.passStatusValue?.text.toLowerCase().replace(" ", ""),
      };
      this.$store.dispatch("getRecipients", payload);
    },
    closeModal() {
      this.modalVisible = false;
      this.selectedItems = [];
    },
    closeDeleteModal() {
      this.deleteModalVisible = false;
      this.selectedItems = [];
      this.refetchLists(true)
    },
    cancelBtnDeleteModal() {
      this.deleteModalVisible = false;
    },
    closeEditModal() {
      this.editModalVisible = false;
      this.refetchLists(true)
    },
    onItemClick(dropId,data) {
      if (dropId == 1) {
        this.editModalVisible = true;
      }

      if (dropId == 2) {

        this.deleteModalVisible = true;
        this.rowId = [data.id];
            }

      if (dropId == 3) {
        const model = {
          id: this.fieldrowId,
          changeToStatus: true,
          clubId: this.$route.params.id,
        };
        changeActiveStatus(model)
          .then((response) => {
            let model2 = {
              clubId: this.$route.params.id,
              entityType: +this.$route.query.type,
              searchQuery: " ",
            };
            this.$store.dispatch("getLists", model2);

            let payload = {
              type: "success",
              Message: "List has been activated.",
            };
            this.$store.dispatch("showNotification", payload);
          })
          .catch((error) => { });
      }
      if (dropId == 4) {
        const model = {
          id: this.fieldrowId,
          changeToStatus: false,
          clubId: this.$route.params.id,
        };

        changeActiveStatus(model)
          .then((response) => {
            let model2 = {
              clubId: this.$route.params.id,
              entityType: +this.$route.query.type,
              searchQuery: " ",
            };
            this.$store.dispatch("getLists", model2);

            let payload = {
              type: "success",
              Message: "List has been deactivated.",
            };
            this.$store.dispatch("showNotification", payload);
          })
          .catch((error) => { });
      }
    },
    // onOpen(e) {
    //   this.fieldrowId = e.id;
    //   var data = this.listData.items?.find((x) => x.id == e.id);

    //   this.passStatuses.splice(2, 1);

    //   if (this.hasEditAccess()) {
    //     if (data.isActive) {
    //       this.passStatuses.push({ id: 2, text: "Inactive" });
    //     } else {
    //       this.passStatuses.push({ id: 2, text: "Activate" });
    //     }
    //   }
    // },
    onOpen(e) {
      this.fieldrowId = e.id;

      this.actionsDropDown = this.actionsDropDown.filter(status => status.id !== 2);

      if (this.hasEditAccess()) {
        if (e.isActive) {
          this.actionsDropDown.push({ id: 2, text: "Inactive" ,dropId: 4});
        } else {
          this.actionsDropDown.push({ id: 2, text: "Activate", dropId: 3});
        }
      }
    },

    navigateToSubscribers(e) {
      if (this.$route.query.type == 3) {
        this.$router.push({
          name: "voucherSubscribers Lists",
          query: {
            listName: e.name,
            type: +this.$route.query.type,
            count : e.audienceCount
          },
          params: {
            id: this.$route.params.id,
            listId: e.id,
          },
        });
      }
      else if (this.$route.query.type == 2) {
        this.$router.push({
          name: "ECSubscribersLists",
          query: {
            listName: e.name,
            type: +this.$route.query.type,
            count : e.audienceCount
          },
          params: {
            id: this.$route.params.id,
            listId: e.id,
          },
        });
      }
      else {
        this.$router.push({
          name: "DpSubscribersLists",
          query: {
            listName: e.name,
            type: +this.$route.query.type,
          },
          params: {
            id: this.$route.params.id,
            listId: e.id,
          },
        });
      }
    },
    getDate(date) {
      var result = moment(date).format("DD-MMM-YYYY");
      return result;
    },
    isNameLimit(name) {
      return name.length <= 50 ? name : name.substring(0, 50) + "...";
    },
  },
};
</script>

<style></style>
