<template src="./EditListModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "../../../../../common/AppModal/AppModal";
import { renameList } from "../../../../../../services/clubDetails/list.service";
import AppLoader from '@/components/common/AppLoader/AppLoader.vue';

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
  },
  props: {
    rowId: {
      type: Number,
      default: null,
    },
  },
  data: function () {
    return {
      visibleDialog: true,
      clubId: this.$route.params.id,
      // listName: "",
      errors: [],
      entityType: +this.$route.query.type
    };
  },
  computed: {
    listDetails() {
      return this.$store.getters.listDetails;
    },
    isLoading() {
      return this.$store.state.list.isListLoading;
    },
  },
  mounted() {
    this.getItem();
  },
  methods: {
    getItem() {
      this.$store.dispatch("getList", {
        id: this.rowId,
        clubId: this.clubId,
      });
    },
    update() {
      var payload = {
        id: this.rowId,
        name: this.listDetails.name,
        clubId: this.clubId,
        entityType: this.entityType
      };
      renameList(payload)
        .then((response) => {
          this.$emit("refetchLists",true);
          let payload = {
            type: "success",
            Message: "List name has been updated.",
          };
          this.$store.dispatch("showNotification", payload);
          this.$emit("closeEditModal");
        })
        .catch((error) => {
          this.errors = error.response.data.errors
        });
    },
    modalHandler(data) {
      this.$emit("closeEditModal");
    },
  },
};
</script>