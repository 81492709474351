<modal
  :title="'Edit List'"
  :handler="true"
  :cssClass="'c-modal-small-2'"
  :isLoading="isLoading"
  type="pulsing"
  @modalHandler="modalHandler"
>
  <template v-slot:content>
    <div class="k-form-field" v-if="!isLoading">
      <label>Give your list a name</label>
      <k-input
        :class="'mb-0'"
        type="text"
        v-model="listDetails.name"
        @keyup="errors.Name[0]=''"
        placeholder="e.g. Subscriber's List"
      >
      </k-input>
      <span class="k-form-error d-block ms-1 mt-2 position-relative">{{this.errors?.Name?.[0]}}</span>
    </div>
  </template>
  <template v-slot:footer>
    <div class="d-flex justify-content-end">
      <div class="me-1">
        <kbutton
          @click="modalHandler"
          :class="'flex-none border-normal-1 base-color'"
          :fill-mode="'outline'"
          :theme-color="'primary'"
          >Cancel</kbutton
        >
      </div>
      <div class="ms-1">
        <kbutton :theme-color="'primary'" @click="update" :class="'flex-none'"
          >Update</kbutton
        >
      </div>
    </div>
  </template>
</modal>
